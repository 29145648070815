<div fxLayoutAlign="center" style="margin-top: 1em;">
  <div fxFlex="70">
    <img src="/assets/i/Serros-Bauman.jpg" style="width: 100%">
    <div id="cardContainer" fxLayoutAlign="center" fxLayoutGap="2%">
      <mat-card fxFlex="20">
        <mat-icon>business_outline</mat-icon>
        <mat-card-title>Address</mat-card-title>
        <mat-card-content>
          <a href="https://goo.gl/maps/gekfH4jrWmeFXmPK9">
            600 Grand Avenue
            <br/>
            Suite 204
            <br/>
            Oakland, CA 94610
          </a>
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="20">
        <mat-icon>smartphone_outline</mat-icon>
        <mat-card-title>Phone</mat-card-title>
        <mat-card-content>
          <a href="tel:415-710-8012">415-710-8012</a>
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="20">
        <mat-icon>email_outline</mat-icon>
        <mat-card-title>Email</mat-card-title>
        <mat-card-content>
          <section>
            <a href="mailto:andy@serros-law.com?subject=Contact from website">andy@serros-law.com</a>
          </section>
          <section>
            <a href="mailto:erikb@serros-law.com?subject=Contact from website">erikb@serros-law.com</a>
          </section>
        </mat-card-content>
      </mat-card>
      <mat-card fxFlex="20">
        <mat-icon>local_printshop</mat-icon>
        <mat-card-title>Fax</mat-card-title>
        <mat-card-content>
          510-336-8279
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
