import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  andyDescription = `Andrew Serros has succeeded in helping a wide array of clients resolve legal disputes in multiple areas of the law. His journey into law began in 2003, when he involved himself in advocating on behalf of reporters and broadcasters as a journalist in legal disputes arising in journalism. After becoming licensed and building experience with established legal organizations, including the American Civil Liberties Union, the Electronic Frontier Foundation, and small firms in San Francisco, Mr. Serros cultivated a thriving solo practice and enjoys being able to directly get results for his clients.`
  erikDescription = `Erik Bauman specializes in tenant's rights and intellectual property. He worked with the Electronic Frontier Foundation and the East Bay Community Law Center before graduating from Berkeley Law and beginning his career in private practice. Mr. Bauman believes in giving individual attention to all of his clients and helping them make calm, calculated decisions in even the most trying legal situations.`;

  constructor() { }

  ngOnInit(): void {
  }

}
