<mat-toolbar color="primary" style="overflow-y: hidden;">
  <!-- <a mat-button routerLink="/" class="navbar-title">Serros Law</a> -->
  <a routerLink="/">
    <img style="width: 250px;" src="/assets/i/serros-bauman-logo-crop.png" alt="The Law Offices of Serros and Bauman">
  </a>
  <a mat-button routerLink="/about">Attorneys</a>
  <button mat-button [matMenuTriggerFor]="areaMenu">Practice Areas</button>
  <span class="spacer"></span>
  <a mat-button routerLink="/contact">Contact Information</a>
  <a mat-raised-button href="mailto:andy@serros-law.com?subject=Contact from website">Contact Us</a>
</mat-toolbar>

<mat-menu #areaMenu>
  <button mat-menu-item routerLink="/law/tenant">Tenant Law</button>
  <button mat-menu-item routerLink="/law/contract">Contracts</button>
  <button mat-menu-item routerLink="/law/intellectual-property">Intellectual Property</button>
  <button mat-menu-item routerLink="/law/family">Family Law</button>
  <button mat-menu-item routerLink="/law/general">General Practice</button>
</mat-menu>
<router-outlet></router-outlet>

<div class="bottom-spacer">&nbsp;</div>
<mat-toolbar id="footer" color="primary">
  <a mat-button href="https://goo.gl/maps/gekfH4jrWmeFXmPK9">
    <mat-icon>business_outline</mat-icon>
    &nbsp;600 Grand Avenue, Suite 204, Oakland, CA 94610
  </a>
  <span class="spacer"></span>
  <a mat-button href="tel:+1-415-710-8012">
    <mat-icon alt="phone">smartphone</mat-icon>
    415-710-8012
  </a>
  <a mat-button href="mailto:andy@serros-law.com?subject=Contact from website">
    <mat-icon>email_outline</mat-icon>
    andy@serros-law.com
  </a>
  <span class="mat-button" style="cursor: inherit;">
    <mat-icon style="vertical-align: middle;" alt="Fax">local_printshop</mat-icon>
    510-336-8279
  </span>
</mat-toolbar>
