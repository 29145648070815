<div fxLayoutAlign="space-around" style="margin-top: 1em;">
  <mat-card fxFlex="40">
    <img mat-card-image src="/assets/i/Andrew-Serros.jpg">
    <mat-card-title>Andrew Serros</mat-card-title>
    <mat-card-subtitle>Partner</mat-card-subtitle>
    <mat-card-content>{{andyDescription}}</mat-card-content>
  </mat-card>
  <mat-card fxFlex="40">
    <img mat-card-image src="/assets/i/Erik-Bauman.jpg">
    <mat-card-title>Erik Bauman</mat-card-title>
    <mat-card-subtitle>Partner</mat-card-subtitle>
    <mat-card-content>{{erikDescription}}</mat-card-content>
  </mat-card>
</div>
