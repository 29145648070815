import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { IndexComponent } from './index/index.component';
import { TenantComponent } from './law/tenant/tenant.component';
import { ContractComponent } from './law/contract/contract.component';
import { IntellectualPropertyComponent } from './law/intellectual-property/intellectual-property.component';
import { FamilyComponent } from './law/family/family.component';
import { GeneralComponent } from './law/general/general.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  {path: '', component: IndexComponent},
  {path: 'about', component: AboutComponent},
  {path: 'law', children: [
    {path: 'tenant', component: TenantComponent},
    {path: 'contract', component: ContractComponent},
    {path: 'intellectual-property', component: IntellectualPropertyComponent},
    {path: 'family', component: FamilyComponent},
    {path: 'general', component: GeneralComponent}
  ]},
  {path: 'contact', component: ContactComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
