<div fxLayoutAlign="center center">
  <div class="eastbay-bg" fxFlex="70">
    <h1>Law Offices of Serros & Bauman</h1>
    <p>California Attorneys centered in the Bay Area</p>
  </div>
</div>
<div fxLayoutAlign="center" style="padding-top: 10px; padding-bottom: 10px;">
  <mat-card fxFlex="80">
    <mat-card-title style="text-align: center;">Serving those in need of legal counsel and representation in the areas of</mat-card-title>
    <mat-card-content fxLayoutGap="6%" fxLayoutAlign="center">
        <a mat-raised-button routerLink="/law/tenant">Tenant Law</a>
        <a mat-raised-button routerLink="/law/contract">Contract Law</a>
        <a mat-raised-button routerLink="/law/intellectual-property">Intellectual Property</a>
        <a mat-raised-button routerLink="/law/family">Family Law</a>
        <a mat-raised-button routerLink="/law/general">General Practice</a>
    </mat-card-content>
  </mat-card>
</div>
